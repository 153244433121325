.Toastify__toast {
  min-height: 4px;
}

.Toastify__toast.Toastify__toast--success {
  position: absolute;
  width: 99%;
  height: fit-content;
  background: #18191b;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 2px;
}
.img-toast {
  position: absolute;
  top: -8px;
  right: -4px;
  margin: 0 !important;
}
.notification-text {
  width: 187px;
  height: 20px;
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #fcfcfc;
}
.statusText {
  position: static;
  /* width: 240px; */
  height: 20px;
  left: calc(50% - 240px / 2 + 28px);
  top: calc(50% - 20px / 2);
  /* Link Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  color: rgba(91, 172, 129, 1);
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.status {
  position: static;
  /* width: 40px; */
  height: 22px;
  left: calc(50% - 40px / 2 - 128px);
  top: calc(50% - 22px / 2);
  /* Text X-Small */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height, or 169% */
  display: flex;
  align-items: center;
  /* Secondary/Default */
  color: #4a5662;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 16px;
}
.order-status {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0px 16px; */
  position: static;
  width: 328px;
  height: 22px;
  left: 0px;
  top: 256px;
  /* Inside Auto Layout */
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
}
.order-details-orderId {
  color: #459eaf;
  position: static;
  width: 296px;
  height: 20px;
  left: 16px;
  top: 0px;
  /* Link Medium */
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 15px;
}
/* checkout module changes */
.address-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;

  position: static;
  height: 112px;
  left: 0px;
  top: 244px;

  background: #f1f1f1;
  /* Grayscale/Line */

  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: -3px 3px 6px rgba(205, 205, 205, 0.2),
    3px -3px 6px rgba(205, 205, 205, 0.2),
    -3px -3px 6px rgba(255, 255, 255, 0.9), 3px 3px 8px rgba(205, 205, 205, 0.9);
  border-radius: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}
.address-title {
  color: #727272;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.address-displayBody {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.address-name {
  /* font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a5662;
  margin-bottom: 10px; */
  font-style: normal;
  /* font-weight: 500; */
  font-size: 10px;
  line-height: 16px;
  color: #bbbbbb;
  margin-bottom: 10px;
}
.full-address {
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4a5662;
}
.loadingDiv {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(102, 102, 102, 0.11);
  /* background-image: url(../images/loader.gif); */
  /* background-image: url(../images/loading-animation.gif); */
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  filter: alpha(opacity=40);
}
.center-top {
  /* margin-top: 100%; */
  position: relative;
  /* top: 50%; */
  top: 39%;
  height: 6em;
}
.add-item-form {
  background: #4a5662;
  display: inline-block;
  width: 100%;
}
.swipe-hint {
  animation: swipe-indicate 5s ease-in infinite;
  position: absolute;
  font-size: 26px;
  margin-top: 17px;
  color: #4a5662;
  font-weight: 600;
}

@keyframes swipe-indicate {
  0% {
    right: 0;
    transform: scale(1);
  }
  20% {
    right: 0;
    transform: scale(1);
  }
  50% {
    right: 50px;
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    right: 50px;
  }
  100% {
    right: 0;
    transform: scale(1);
  }
}
::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar */
  width: 4px; /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}
.restRoundImage {
  width: 24px;
  border-radius: 20px;
  vertical-align: bottom;
  margin-right: 10px;
  font-weight: 600;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgb(221 221 224 / 20%),
    0.3125rem -0.3125rem 0.625rem 0 rgb(221 221 224 / 20%),
    -0.3125rem -0.3125rem 0.625rem 0 rgb(255 255 255 / 90%),
    0.3125rem 0.3125rem 0.8125rem 0 rgb(220 220 220 / 90%);
  -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgb(221 221 224 / 20%),
    0.3125rem -0.3125rem 0.625rem 0 rgb(221 221 224 / 20%),
    -0.3125rem -0.3125rem 0.625rem 0 rgb(255 255 255 / 90%),
    0.3125rem 0.3125rem 0.8125rem 0 rgb(220 220 220 / 90%);
  border-radius: 0.625rem;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  padding: 5px 10px;
  box-sizing: border-box;
  outline: none;
  color: #4a5662;
  font-family: "Graphik", sans-serif;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content p {
  padding-bottom: 11px;
  padding-top: 11px;
  padding-left: 10px;
  margin-bottom: 0px;
}
.dropdown-content p:active {
  box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgb(221 221 224 / 20%),
    inset 0.125rem -0.125rem 0.25rem 0 rgb(221 221 224 / 20%),
    inset -0.125rem -0.125rem 0.25rem 0 rgb(255 255 255 / 90%),
    inset 0.125rem 0.125rem 0.3125rem 0 rgb(220 220 220 / 90%);
  -webkit-box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgb(221 221 224 / 20%),
    inset 0.125rem -0.125rem 0.25rem 0 rgb(221 221 224 / 20%),
    inset -0.125rem -0.125rem 0.25rem 0 rgb(255 255 255 / 90%),
    inset 0.125rem 0.125rem 0.3125rem 0 rgb(220 220 220 / 90%);
}
.rotate180 {
  transform: rotate(180deg);
}
.limit-item-name-length {
  max-width: calc(100% - 47px);
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.6em;
  line-height: 1.8em;
}

.rating-fix-button {
  position: fixed;
  width: 92% !important;
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  margin-bottom: 5px !important;
}
.Toastify__toast-body {
  padding-top: 0px !important;
}
.item-name-width {
  width: calc(100% - 92px);
}

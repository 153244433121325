@import "../../../../assets/scss/variables";

.modal {
  position:         fixed;
  z-index:          40;
  left:             50%;
  top:              0;
  width:            100%;
  max-width:        768px;
  height:           100%;
  background-color: $textColor;
  transform:        translate(-50%, 0);
  overflow:         auto;
  .back {
    cursor:          pointer;
    position:        absolute;
    z-index:         50;
    left:            8px;
    top:             16px;
    width:           30px;
    height:          30px;
    border-radius:   10px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    margin-right:    8px;
    background:      $textColor;
    box-shadow:      -4px 4px 8px rgba(62, 72, 82, 0.2), 4px -4px 8px rgba(62, 72, 82, 0.2), -4px -4px 8px rgba(86, 100, 114, 0.9), 4px 4px 10px rgba(62, 72, 82, 0.9);
    transition:      .2s linear;
    &:active {
      box-shadow: inset -4px 4px 8px rgba(62, 72, 82, 0.2), inset 4px -4px 8px rgba(62, 72, 82, 0.2), inset -4px -4px 8px rgba(86, 100, 114, 0.9), 4px 4px 10px rgba(62, 72, 82, 0.9);
    }
    svg path {
      fill: $whiteColor;
    }
  }
  .header {
    padding:       20px 0 14px 60px;
    margin-bottom: 0;
    color:         $whiteColor;
    position:      relative;
    z-index:       45;
    min-height:    52px;
  }
  &.light {
    .back {
      background: #657280;
      box-shadow: -4px 4px 8px rgba(91, 103, 115, 0.2), 4px -4px 8px rgba(91, 103, 115, 0.2), -4px -4px 8px rgba(111, 125, 141, 0.9), 4px 4px 10px rgba(91, 103, 115, 0.9);
      &:active {
        box-shadow: inset -2px 2px 4px 0 rgba(91, 103, 115, 0.2), inset 2px -2px 4px 0 rgba(91, 103, 115, 0.2), inset -2px -2px 4px 0 rgba(111, 125, 141, 0.9), inset 2px 2px 5px 0 rgba(91, 103, 115, 0.9);
      }
    }
  }
  &.black {
    background-color: $secondColor;
    .back {
      background:    #39434E;
      box-shadow:    -5px 5px 10px rgba(48, 57, 66, 0.2), 5px -5px 10px rgba(48, 57, 66, 0.2), -5px -5px 10px rgba(66, 77, 90, 0.9), 5px 5px 13px rgba(48, 57, 66, 0.9), inset 1px 1px 2px rgba(66, 77, 90, 0.3), inset -1px -1px 2px rgba(48, 57, 66, 0.5);
      border-radius: 10px;
      &:active {
        box-shadow: inset -5px 5px 10px rgba(48, 57, 66, 0.2), inset 5px -5px 10px rgba(48, 57, 66, 0.2), inset -5px -5px 10px rgba(66, 77, 90, 0.9), inset 5px 5px 13px rgba(48, 57, 66, 0.9), inset 1px 1px 2px rgba(66, 77, 90, 0.3), -1px -1px 2px rgba(48, 57, 66, 0.5);
      }
    }
  }
  &.event {
    .inner {
      background-color: $backgroundColor;
      hr {
        opacity: 1;
      }
      .close path {
        fill: $blackColor;
      }
      * {
        color: inherit;
      }
    }
  }
  &.noFullScreen {
    z-index:          45;
    background-color: rgba(0, 0, 0, 0.8);
    display:          flex;
    flex-direction:   column;
    justify-content:  flex-end;
  }
  &.rating {
    .inner {
      background-color: #39434E;
      .header {
        padding-bottom: 0;
      }
    }
  }
  .inner {
    background-color: $textColor;
    width:            100%;
    box-shadow:       4px 4px 16px rgba(0, 0, 0, 0.38);
    border-radius:    16px 16px 0 0;
    padding:          0 15px 15px 15px;
    hr {
      border:           none;
      height:           1px;
      background-color: #e9e9e9;
      opacity:          .4;
      margin-bottom:    18px;
    }
    * {
      color: $whiteColor;
    }
    .header {
      padding:         20px 0 14px 0;
      margin-bottom:   0;
      display:         flex;
      align-items:     center;
      justify-content: space-between;
      .close {
        cursor: pointer;
        &:active {
          opacity: .7;
        }
      }
    }
  }
}
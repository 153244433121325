@import "../../../assets/scss/variables";

.vegan {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 0.3125rem;
  border: 0.125rem solid $defaultColor;
  margin-right: 0.5rem;
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 67%;
    height: 67%;
    background: $defaultColor;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.nonVeg {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 0.3125rem;
  border: 0.125rem solid #ef4b4b;
  margin-right: 0.5rem;
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 67%;
    height: 67%;
    background: #ef4b4b;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.egg {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 0.3125rem;
  border: 0.125rem solid #f5CA3D;
  margin-right: 0.5rem;
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 67%;
    height: 67%;
    background: #f5CA3D;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

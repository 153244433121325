@font-face {
  font-family: 'Graphik';
  src:         url('../fonts/GraphikLight.otf') format('opentype');
  font-weight: 300;
  font-style:  normal;
}

@font-face {
  font-family: 'Graphik';
  src:         url('../fonts/GraphikRegular.otf') format('opentype');
  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-family: 'Graphik';
  src:         url('../fonts/GraphikMedium.otf') format('opentype');
  font-weight: 500;
  font-style:  normal;
}

@font-face {
  font-family: 'Graphik';
  src:         url('../fonts/GraphikSemibold.otf') format('opentype');
  font-weight: 600;
  font-style:  normal;
}

@font-face {
  font-family: 'Graphik';
  src:         url('../fonts/GraphikBold.otf') format('opentype');
  font-weight: 700;
  font-style:  normal;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style:  normal;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style:  normal;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style:  normal;
}
@font-face {
  font-family: 'Inter';
  src:         url('../fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style:  normal;
}
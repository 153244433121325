@import "../../../../assets/scss/variables";

.complaints {
  hr {
    border:           none;
    height:           1px;
    background-color: #e9e9e9;
    opacity:          .4;
    margin-bottom:    18px;
  }
  * {
    color: $whiteColor;
  }
}

.bottom {
  padding:  16px 11px 6px 11px;
  height:   calc(100vh - 80px);
  overflow: auto;

  .item {
    margin-bottom: 24px;
    padding:       16px 8px 13px 8px;
    background:    $textColor;
    box-shadow:    inset -2px 2px 4px rgba(59, 69, 78, 0.2), inset 2px -2px 4px rgba(59, 69, 78, 0.2), inset -2px -2px 4px rgba(89, 103, 118, 0.9), inset 2px 2px 5px rgba(59, 69, 78, 0.9);
    border-radius: 10px;
    &.hide {
      .subTitle {
        svg {
          transform: rotate(180deg);
        }
      }
      .panel {
        display: none;
      }
    }
  }
  .top {
    .title {
      display:       flex;
      align-items:   center;
      color:         $whiteColor;
      margin-bottom: 11px;
      .price {
        margin-left: auto;
        font-size:   12px;
      }
    }
  }
  .description {
    color:          $placeholderColor;
    font-size:      12px;
    letter-spacing: 0;
  }
  .subTitle {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    text-transform:  inherit;
    cursor:          pointer;
    margin-bottom:   0;
    &:active {
      color: $defaultColor;
    }
    svg {
      transition:     .2s linear;
      pointer-events: none;
    }
  }
  .panel {
    margin-top: 18px;
    h4 {
      font-size:     13px;
      margin-bottom: 16px;
      span {
        text-transform: uppercase;
        color:          $placeholderColor;
      }
    }
    label {
      cursor: pointer;
    }
    .something {
      font-size:       12px;
      color:           #eaf2fb;
      text-decoration: underline;
      background:      transparent;
      border:          none;
      box-shadow:      none;
      padding:         10px 8px;
      &:active {
        text-decoration: none;
      }
    }
  }
}
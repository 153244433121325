@import "../../../../assets/scss/variables";

.order {
  padding: 24px 11px 16px 11px;
  * {
    color: $whiteColor;
  }
  .warning {
    cursor:          pointer;
    position:        absolute;
    z-index:         50;
    right:           12px;
    top:             11px;
    min-width:       32px;
    width:           32px;
    height:          32px;
    border-radius:   10px;
    background:      $secondColor;
    box-shadow:      -5px 5px 10px rgba(48, 57, 66, 0.2), 5px -5px 10px rgba(48, 57, 66, 0.2), -5px -5px 10px rgba(66, 77, 90, 0.9), 5px 5px 13px rgba(48, 57, 66, 0.9), inset 1px 1px 2px rgba(66, 77, 90, 0.3), inset -1px -1px 2px rgba(48, 57, 66, 0.5);
    display:         flex;
    align-items:     center;
    justify-content: center;
    transition:      .2s linear;
    svg path {
      fill: $whiteColor;
    }
    &:active {
      box-shadow: inset -5px 5px 10px rgba(48, 57, 66, 0.2), inset 5px -5px 10px rgba(48, 57, 66, 0.2), inset -5px -5px 10px rgba(66, 77, 90, 0.9), inset 5px 5px 13px rgba(48, 57, 66, 0.9), inset 1px 1px 2px rgba(66, 77, 90, 0.3), inset -1px -1px 2px rgba(48, 57, 66, 0.5);
    }
  }
  .card {
    margin:        0 6px;
    padding:       8px 8px 16px;
    background:    $secondColor;
    box-shadow:    inset -2px 2px 4px rgba(42, 50, 58, 0.2), inset 2px -2px 4px rgba(42, 50, 58, 0.2), inset -2px -2px 4px rgba(72, 84, 98, 0.9), inset 2px 2px 5px rgba(42, 50, 58, 0.9);
    border-radius: 10px;
    .item {
      background:    linear-gradient(90deg, #DAE2F8 0%, #D6A4A4 100%);
      padding:       21px 0 15px 15px;
      border-radius: 10px;
    }
    .description {
      display:         flex;
      align-items:     flex-start;
      justify-content: space-between;
      h2 {
        color: $secondColor;
      }
      img {
        width:       45%;
        margin-left: 10px;
        max-width:   200px;
      }
    }
    p {
      color:         $secondColor;
      margin-bottom: 0;
    }
    .buttons {
      display:         flex;
      align-items:     center;
      justify-content: space-between;
      margin-top:      16px;
      .button {
        width:           46%;
        padding:         3px;
        background:      $secondColor;
        box-shadow:      -5px 5px 10px rgba(45, 53, 62, 0.2), 5px -5px 10px rgba(45, 53, 62, 0.2), -5px -5px 10px rgba(69, 81, 94, 0.9), 5px 5px 13px rgba(45, 53, 62, 0.9);
        border-radius:   10px;
        font-weight:     500;
        font-size:       12px;
        color:           $whiteColor;
        height:          44px;
        border:          2px solid $secondColor;
        display:         flex;
        align-items:     center;
        justify-content: center;
        text-transform:  inherit;
        &:nth-child(2) {
          background-color: #18191B;
        }
        .currency {
          width:           30px;
          height:          30px;
          border-radius:   50%;
          display:         flex;
          align-items:     center;
          justify-content: center;
          background:      #18191B;
          box-shadow:      inset -2px 2px 4px rgba(10, 10, 11, 0.2), inset 2px -2px 4px rgba(10, 10, 11, 0.2), inset -2px -2px 4px rgba(38, 40, 43, 0.9), inset 2px 2px 5px rgba(10, 10, 11, 0.9);
          margin-left:     16px;
          img {
            max-height: 40%;
          }
        }
      }
    }
  }
}
@import "./assets/scss/variables";
@import "./assets/scss/font";

*, *:after, *:before {
  margin:      0;
  padding:     0;
  box-sizing:  border-box;
  outline:     none;
  color:       $textColor;
  font-family: 'Graphik', sans-serif;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  width: 100%;
}

ul {
  list-style: none;
}

a {
  cursor:          pointer;
  text-decoration: none;
  font-size:       1rem;
  line-height:     1.39;
  color:           #45dfd5;
  &:active {
    opacity: .7;
  }
}

body, html {
  margin:  0;
  padding: 0;
  height:  100%;
}

body {
  background: $backgroundColor;
}

html {
  font-size: 16px;
}

section {
  flex:           1 1 auto;
  display:        flex;
  flex-direction: column;
  width:          100%;
}

h2 {
  font-size:     20px;
  font-weight:   500;
  line-height:   1.2;
  margin-bottom: 16px;
  color:         $secondColor;

}

h3 {
  font-size:     16px;
  font-weight:   500;
  line-height:   1.25;
  margin-bottom: 16px;

}

h4 {
  font-size:     14px;
  font-weight:   500;
  line-height:   1.33;
  margin-bottom: 26px;
}

h5 {
  font-size:     12px;
  font-weight:   500;
  line-height:   1.33;
  margin-bottom: 26px;
}

h6 {
  font-size:     10px;
  font-weight:   500;
  line-height:   1.33;
  margin-bottom: 16px;
}

p {
  font-weight:    400;
  font-size:      12px;
  line-height:    1.33;
  margin-bottom:  16px;
  letter-spacing: 0.4px;
}


#root {
  height: 100%;
}

button {
  cursor:      pointer;
  line-height: 1rem;
  font-weight: 500;
}

.btn {
  background-color:   $blackColor;
  padding:            15px;
  color:              $whiteColor;
  font-size:          14px;
  line-height:        1;
  border-radius:      50px;
  cursor:             pointer;
  transition:         .2s linear;
  width:              100%;
  border:             none;
  text-transform:     uppercase;
  font-weight:        600;
  user-select:        none;
  box-shadow:         -5px 5px 10px rgba(212, 212, 212, 0.2), 5px -5px 10px rgba(212, 212, 212, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(212, 212, 212, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(212, 212, 212, 0.5);
  -webkit-box-shadow: -5px 5px 10px rgba(212, 212, 212, 0.2), 5px -5px 10px rgba(212, 212, 212, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(212, 212, 212, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(212, 212, 212, 0.5);
  &:active {
    opacity: .7;
  }
  &-card {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    padding:         8px 8px 8px 16px;
    height:          44px;
    background:      linear-gradient(135deg, #459EAF 0%, #007991 100%);
    box-shadow:      -5px 5px 10px rgba(48, 57, 66, 0.2), 5px -5px 10px rgba(48, 57, 66, 0.2), -5px -5px 10px rgba(66, 77, 90, 0.9), 5px 5px 13px rgba(48, 57, 66, 0.9);
    border-radius:   10px;
    font-weight:     600;
    font-size:       12px;
    border:          none;
    width:           calc(100% - 16px);
    max-width:       750px;
    position:        fixed;
    z-index:         1;
    bottom:          8px;
    left:            50%;
    transform:       translate(-50%, 0);
  }
  &-primary {
    padding:         8px 16px;
    background:      linear-gradient(90deg, #97D8D8 0%, #5AD7DF 100%, #17A2C0 100%);
    box-shadow:      -4px 4px 8px rgba(215, 215, 215, 0.2), 4px -4px 8px rgba(215, 215, 215, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.9), 4px 4px 10px rgba(215, 215, 215, 0.9);
    border-radius:   10px;
    font-size:       13px;
    letter-spacing:  1px;
    height:          44px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    min-width:       100px;
  }
  &-black {
    background:      #18191B;
    box-shadow:      -5px 5px 10px rgba(45, 53, 62, 0.2), 5px -5px 10px rgba(45, 53, 62, 0.2), -5px -5px 10px rgba(69, 81, 94, 0.9), 5px 5px 13px rgba(45, 53, 62, 0.9);
    border-radius:   10px;
    font-size:       13px;
    letter-spacing:  1px;
    height:          38px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    min-width:       100px;
  }
  &-white {
    color:           $textColor;
    background:      $whiteColor;
    box-shadow:      -4px 4px 8px rgba(215, 215, 215, 0.2), 4px -4px 8px rgba(215, 215, 215, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.9), 4px 4px 10px rgba(215, 215, 215, 0.9);
    border-radius:   10px;
    font-size:       13px;
    letter-spacing:  1px;
    height:          38px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    min-width:       100px;
  }
  &-second {
    padding:         8px 16px;
    border-radius:   10px;
    font-size:       13px;
    letter-spacing:  1px;
    height:          44px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    background:      #657280;
    box-shadow:      none;


  }
}

label {
  margin-bottom: 16px;
  display:       block;
  position:      relative;
  user-select:   none;
  font-size:     14px;
  font-weight:   400;
  line-height:   1;
  textarea {
    padding:            16px;
    background:         transparent;
    height:             104px;
    top:                48px;
    box-shadow:         inset -2px 2px 4px rgba(63, 73, 83, 0.2), inset 2px -2px 4px rgba(63, 73, 83, 0.2), inset -2px -2px 4px rgba(85, 99, 113, 0.9), inset 2px 2px 5px rgba(63, 73, 83, 0.9);
    border-radius:      10px;
    width:              100%;
    color:              $whiteColor;
    border:             none;
    line-height:        1.4;
    appearance:         inherit;
    -webkit-appearance: inherit;
    &::placeholder {
      color: $placeholderColor;
    }
  }
  input {
    width:              100%;
    height:             48px;
    font-size:          14px;
    line-height:        1;
    color:              $textColor;
    padding:            10px 16px;
    background:         $backgroundColor;
    border:             none;
    box-shadow:         inset -2px 2px 4px rgba(190, 192, 196, 0.2), inset 2px -2px 4px rgba(190, 192, 196, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(190, 192, 196, 0.9);
    -webkit-box-shadow: inset -2px 2px 4px rgba(190, 192, 196, 0.2), inset 2px -2px 4px rgba(190, 192, 196, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(190, 192, 196, 0.9);
    border-radius:      10px;
    transition:         .2s linear;
    &::placeholder {
      color:   $placeholderColor;
      opacity: .6;
    }
    &:active, &:focus {
      opacity: .7;
    }
  }
  input[type="checkbox"], input[type="radio"] {
    width:              auto;
    box-shadow:         none;
    -webkit-appearance: checkbox;
    -moz-appearance:    checkbox; /* Firefox */
    height:             auto;
    margin-right:       8px;
    border-radius:      50%;
  }
  &.radio, &.checkbox {
    display:     flex;
    align-items: center;
    input:checked + .icon:before, input:checked + .icon:after {
      display: block;
    }
    .icon {
      width:         24px;
      height:        24px;
      background:    $textColor;
      box-shadow:    inset -2px 2px 4px rgba(59, 69, 78, 0.2), inset 2px -2px 4px rgba(59, 69, 78, 0.2), inset -2px -2px 4px rgba(89, 103, 118, 0.9), inset 2px 2px 5px rgba(59, 69, 78, 0.9);
      border-radius: 24px;
      display:       inline-block;
      margin-right:  8px;
      position:      relative;
      &:before {
        content:       '';
        position:      absolute;
        z-index:       1;
        left:          25%;
        right:         25%;
        top:           25%;
        bottom:        25%;
        background:    linear-gradient(90deg, #97D8D8 0%, #5AD7DF 100%, #17A2C0 100%);
        border-radius: 24px;
        display:       none;
      }
    }
  }
  &.checkbox {
    .icon {
      border-radius: 8px;
      &:before {
        content:       '';
        left:          12.5%;
        right:         12.5%;
        top:           12.5%;
        bottom:        12.5%;
        border-radius: 6px;
      }
      &:after {
        content:         '';
        position:        absolute;
        z-index:         1;
        background:      url("./assets/images/icons/check.svg") no-repeat center center;
        background-size: contain;
        left:            16.67%;
        right:           16.67%;
        top:             16.67%;
        bottom:          16.67%;
        display:         none;
      }
    }
  }
  &.phone {
    margin-bottom: 0;
    &:before {
      content:         '';
      position:        absolute;
      z-index:         1;
      left:            42px;
      top:             50%;
      transform:       translate(0, -50%);
      width:           8px;
      height:          7px;
      background:      url("./assets/images/icons/arrow-down.svg") no-repeat center center;
      background-size: contain;
    }
    input {
      padding-left: 64px;
    }
    select {
      padding:            0 6px;
      background:         transparent;
      border:             transparent;
      position:           absolute;
      z-index:            1;
      left:               6px;
      top:                50%;
      transform:          translate(0, -50%);
      font-size:          14px;
      font-weight:        500;
      color:              $textColor;
      appearance:         none;
      -webkit-appearance: none;
    }
  }
  &.digit {
    width:         48px;
    height:        48px;
    margin-right:  8px;
    margin-bottom: 0;
    input {
      appearance:         none;
      -webkit-appearance: none;
      -moz-appearance:    textfield;
      height:             100%;
      font-size:          20px;
      text-align:         center;
      text-transform:     uppercase;
      background-color:   #e8dfe4;
      padding:            10px;
      box-shadow:         inset -2px 2px 4px rgba(186, 178, 182, 0.2), inset 2px -2px 4px rgba(186, 178, 182, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(186, 178, 182, 0.9);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    appearance:         none;
    -webkit-appearance: none;
    -moz-appearance:    none; /* Firefox */
    margin:             0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input {
    appearance:         none;
    -webkit-appearance: none;
    -moz-appearance:    textfield; /* Firefox */
  }
}

.no-scroll {
  overflow: hidden;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}


header {
  nav ul li a {
    &.active {
      color:  #3cbcb4;
      &:before {
        background-color: #3cbcb4;
        box-shadow: 0 6px 10px #83DBD6;
      }
    }
  }
}

footer {
  ul li a.active {
    box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2), inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2), inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9), inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9) !important;
  }
}

// calendar
.calendar {
  margin:          0 auto 0 auto;
  border-spacing:  0;
  border-collapse: collapse;
  td {
    padding:     13px;
    text-align:  center;
    font-size:   10px;
    font-weight: 500;
    color:       $placeholderColor;
    position:    relative;
    &.event {
      &:before {
        content:          '';
        position:         absolute;
        z-index:          3;
        left:             50%;
        top:              50%;
        width:            30px;
        height:           30px;
        transform:        translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.32);
        border-radius:    50%;
      }
    }
    img {
      position:      absolute;
      z-index:       1;
      width:         30px;
      height:        30px;
      left:          50%;
      top:           50%;
      transform:     translate(-50%, -50%);
      border-radius: 50%;
    }
    span {
      position: relative;
      z-index:  6;
      color:    #bbbbbb;
    }
    &.is-disabled {
      color: transparent;
    }
    &.title {
      text-transform: uppercase;
      padding-bottom: 24px;
    }
  }
}


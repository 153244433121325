@import "../../../assets/scss/variables";

.panel {
  display:          flex;
  align-items:      center;
  padding:          8px 8px 20px 8px;
  width:            100%;
  box-shadow:       -1px -1px 3px $whiteColor, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
  border-radius:    0 0 10px 10px;
  position:         fixed;
  z-index:          10;
  left:             50%;
  top:              0;
  background-color: $backgroundColor;
  max-width:        768px;
  transform:        translate(-50%, 0);
  &.noBorder {
    box-shadow: none;
  }
  .icon {
    min-width:          40px;
    width:              40px;
    height:             40px;
    border-radius:      10px;
    box-shadow:         -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2), 0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2), -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9), 0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
    -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2), 0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2), -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9), 0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
    display:            flex;
    align-items:        center;
    justify-content:    center;
    transition:         .2s linear;
    margin-right:       8px;
    &:active {
      box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2), inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2), inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9), inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9),;
    }
  }
  .verified {
    width:        24px;
    margin-right: auto;
    margin-left:  10px;
  }
  h2 {
    margin-bottom:   0;
    margin-left:     8px;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    width:           100%;
  }
}
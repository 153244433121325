@import "../../../../assets/scss/variables";

.share {
  .digit {
    width:           45px;
    height:          45px;
    background:      $textColor;
    border-radius:   16px;
    font-weight:     500;
    font-size:       20px;
    display:         inline-flex;
    justify-content: center;
    align-items:     center;
    color:           $backgroundColor;
    margin:          0 8px 16px 0;
    box-shadow:      inset -2px 2px 4px rgba(56, 65, 74, 0.2), inset 2px -2px 4px rgba(56, 65, 74, 0.2), inset -2px -2px 4px rgba(92, 107, 122, 0.9), inset 2px 2px 5px rgba(56, 65, 74, 0.9);
  }
  .description {
    color:   $whiteColor;
    opacity: .4;
  }
  .panel {
    margin-top: 18px;
    h4 {
      font-size:     13px;
      margin-bottom: 16px;
      span {
        text-transform: uppercase;
        color:          $placeholderColor;
      }
    }
    label {
      cursor: pointer;
    }
    .something {
      font-size:       12px;
      color:           #eaf2fb;
      text-decoration: underline;
      background:      transparent;
      border:          none;
      box-shadow:      none;
      font-weight:     400;
      margin-bottom:   80px;
      margin-left:     16px;
      &:active {
        text-decoration: none;
      }
    }
  }
}
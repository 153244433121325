@import "../../../assets/scss/variables";

.items {
  margin-top: 1.5rem;
  &.hide {
    .title {
      svg {
        transform: rotate(180deg);
      }
    }
    .panel {
      display: none;
    }
  }
  &.mod {
    margin-top: 21px;
    .item {
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      margin-left: 8px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $blackColor;
  font-size: 13px;
  &.toggle {
    text-transform: inherit;
    color: $textColor;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 19px;
    &:active {
      color: $defaultColor;
    }
  }
  hr {
    flex: 1 1 auto;
    height: 0.0625rem;
    background: rgba(74, 86, 98, 0.1);
    margin: 0 0.5rem;
    border: none;
    pointer-events: none;
  }
  svg {
    transition: 0.2s linear;
    pointer-events: none;
  }
}

.item {
  border-radius: 0.625rem;
  background-color: #f6f6f6;
  box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
    inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
    inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
    inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
  display: flex;
  align-items: center;
  padding: 12px 9px;
  margin-bottom: 1rem;
  min-height: 87px;
  h4 {
    margin-bottom: 0;
  }
  img {
    width: 71px;
    height: 71px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 0.5rem;
  }
  .description {
    flex: 1 1 auto;
    padding-left: 0.5rem;
  }
  .list {
    display: flex;
    align-items: center;
  }
  .price {
    display: flex;
    align-items: center;
    h4 {
      line-height: 1;
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .descriptionClamp {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .reducedWidth {
    max-width: calc(100vw - 186px) !important;
  }  
}

.panel {
  &.mod {
    padding: 14px 0 14px 0;
    background: transparent;
    box-shadow: inset -3px -3px 7px #ffffff,
      inset 2px 2px 5px rgba(174, 174, 192, 0.4);
    border-radius: 10px;
    .item {
      box-shadow: none;
      min-height: auto;
      position: relative;
      background: transparent;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 0;
      h4,
      h5 {
        font-weight: 400;
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 5px;
      }
    }
  }
  .link {
    font-size: 12px;
    font-weight: 500;
    color: #76dfe5;
    text-decoration: underline;
    cursor: pointer;
    padding: 4px 10px;
    &:active {
      text-decoration: none;
    }
  }
}

@import "../../../assets/scss/variables";

.cooking {
  position:         fixed;
  z-index:          40;
  top:              0;
  left:             50%;
  width:            100%;
  height:           100%;
  background-color: rgba(0, 0, 0, 0);
  display:          flex;
  flex-direction:   column;
  justify-content:  flex-end;
  transition:       0s linear;
  visibility:       hidden;
  max-width:        768px;
  transform:        translate(-50%, 0);
  &.open {
    background-color: rgba(0, 0, 0, 0.8);
    visibility:       visible;
    .inner {
      transform: translate(0, 0);
    }
  }
  .inner {
    border-radius:  16px 16px 0 0;
    padding:        16px;
    transform:      translate(0, 100%);
    transition:     0s linear;
    display:        flex;
    flex-direction: column;
    background:     $backgroundColor;
    box-shadow:     4px 4px 16px rgba(0, 0, 0, 0.38);
    .avatars {
      display:     flex;
      align-items: center;
      flex-wrap:   wrap;
      .avatar {
        width:         52px;
        height:        52px;
        min-height:    52px;
        border-radius: 50%;
        margin-right:  12px;
        margin-bottom: 12px;
        display:       inline-flex;
        cursor:        pointer;
        position:      relative;
        z-index:       1;
        &:active, &.active {
          img, span {
            border: 3px solid rgba(118, 215, 220, 1);
          }
        }
        img {
          border-radius: 50%;
          border:        3px solid transparent;
        }
        span {
          display:         flex;
          align-items:     center;
          justify-content: center;
          width:           100%;
          height:          100%;
          font-size:       20px;
          font-weight:     500;
          color:           $whiteColor;
          background:      linear-gradient(90deg, rgba(151, 216, 216, 0.6) 0%, rgba(90, 215, 223, 0.6) 100%, rgba(23, 162, 192, 0.6) 100%);
          position:        relative;
          border-radius:   50%;
          border:          3px solid transparent;
          &.status:before {
            content:       '';
            position:      absolute;
            z-index:       3;
            width:         12px;
            height:        12px;
            left:          28px;
            top:           -6px;
            background:    linear-gradient(76.06deg, #F48535 -104%, #F4C51D 144.68%);
            border:        2px solid $backgroundColor;
            transform:     matrix(1, 0, 0, -1, 0, 0);
            border-radius: 50%;
          }
        }
      }
    }
    hr {
      height:           1px;
      border:           none;
      background-color: #E9E9E9;
      margin:           0 0 16px 0;
    }
    form {
      height:         100%;
      display:        flex;
      flex-direction: column;
    }
    label {
      font-weight: 500;
      textarea {
        margin-top: 8px;
        background: #F6F6F6;
        box-shadow: inset -2px 2px 4px rgba(209, 209, 209, 0.2), inset 2px -2px 4px rgba(209, 209, 209, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(209, 209, 209, 0.9);
        color:      $blackColor;
      }
    }
    h3 {
      display:         flex;
      align-items:     center;
      justify-content: space-between;
      .close {
        cursor: pointer;
        path {
          fill: $textColor;
        }
        &:active {
          opacity: .7;
        }
      }
    }
  }
}
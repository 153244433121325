@import "../../../../assets/scss/variables";

.dialogs {
  position:         fixed;
  z-index:          30;
  left:             0;
  top:              0;
  height:           100%;
  width:            100%;
  background-color: rgba(0, 0, 0, 0.8);
  display:          flex;
  align-items:      center;
  justify-content:  center;
  .inner {
    width:         328px;
    max-width:     90%;
    padding:       16px;
    position:      relative;
    background:    $backgroundColor;
    box-shadow:    0 0 20px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    h3 {
      margin-bottom: 8px;
    }
  }
  .buttons {
    margin-top:      24px;
    display:         flex;
    align-items:     center;
    justify-content: flex-end;
    button {
      width:     auto;
      font-size: 12px;
      height:    32px;
      &:last-child {
        margin-left: 16px;
      }
    }
  }
}
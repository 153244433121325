@import "../../../../assets/scss/variables";

.registerEvent {
  padding:        0 15px 32px 15px;
  overflow:       auto;
  display:        flex;
  flex-direction: column;
  margin:         0 -15px;
  .about {
    margin-bottom: 6px;
  }
}

.form {
  .whatsapp {
    position:  absolute;
    z-index:   1;
    right:     6px;
    width:     20px;
    top:       50%;
    transform: translate(0, -50%);
  }
  input {
    height:           40px;
    background-color: #f6f6f6;
    border:           1px solid #E9E9E9;
    box-shadow:       inset -1px 1px 2px rgba(216, 216, 216, 0.2), inset 1px -1px 2px rgba(216, 216, 216, 0.2), inset -1px -1px 2px rgba(255, 255, 255, 0.9), inset 1px 1px 3px rgba(216, 216, 216, 0.9);
  }
  .taxes {
    margin-top:      18px;
    margin-bottom:   32px;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    h5 {
      margin-bottom: 0;
    }
  }

}

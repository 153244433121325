.info {
  display:     flex;
  flex-wrap:   wrap;
  align-items: center;
  .item {
    margin-bottom: 16px;
    width:         42%;
    display:       flex;
    align-items:   center;
    &:last-child {
      width: 100%;
    }
    svg {
      width:        16px;
      margin-right: 8px;
    }
    h5 {
      margin-bottom: 0;
      span {
        font-weight: 400;
      }
    }
  }
  &.mod {
    .item {
      width:         50%;
      margin-bottom: 24px;
      &.people, &:last-child {
        width: 100%;
      }
      svg {
        width: 20px;
      }
      h5 {
        font-size: 14px;
      }
    }
  }
}

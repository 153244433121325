@import "../../../assets/scss/variables";

.header {
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  padding: 16px 15px 0 15px;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background: $backgroundColor;
  box-shadow: -2px 2px 4px rgba(212, 212, 212, 0.2),
    2px -2px 4px rgba(212, 212, 212, 0.2),
    -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(212, 212, 212, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(212, 212, 212, 0.5);
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin-bottom: 0;
      color: #657280;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 2rem;
        height: 2rem;
        border-radius: 0.625rem;
        box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
          0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
          -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
          0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
        -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0
            rgba(221, 221, 224, 0.2),
          0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
          -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
          0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
        &:active {
          box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
            inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
            inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
            inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
          -webkit-box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
            inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
            inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
            inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
        }
        &:last-child {
          margin-left: 1rem;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          transition: 0.2s linear;
        }
      }
    }
  }
  nav {
    width: 100vw;
    left: -15px;
    position: relative;
    max-width: 768px;
    margin: 1.25rem auto 0 auto;
    ul {
      // change by mohit
      display: -webkit-box;
      overflow-x: scroll;
      // display:         flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      li {
        border-radius: 10px;
        width: 25%;
        position: relative;
        padding: 0 10px;
      }
      a {
        width: 100%;
        font-size: 0.8125rem;
        font-weight: 600;
        line-height: 1.69;
        color: rgba(74, 86, 98, 0.4);
        display: inline-block;
        padding: 8px 0 8px 0;
        text-align: center;
        border-radius: 10px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: transparent;
          border-radius: 10px;
          transition: 0.2s linear;
        }
        &.disable {
          pointer-events: none;
        }
      }
    }
  }
}

@import "../../../assets/scss/variables";

.items {
  margin-top:      31px;
  display:         flex;
  justify-content: space-between;
  flex-wrap:       wrap;
  &.hide {
    .title {
      svg {
        transform: rotate(180deg);
      }
    }
    .panel {
      display: none;
    }
  }
}

.title {
  display:         flex;
  align-items:     center;
  justify-content: space-between;
  width:           100%;
  margin-bottom:   18px;
  &.toggle {
    text-transform: inherit;
    color:          $textColor;
    font-size:      14px;
    cursor:         pointer;
    &:active {
      color: $defaultColor;
    }
  }
  hr {
    flex:           1 1 auto;
    height:         0.0625rem;
    background:     rgba(74, 86, 98, 0.1);
    margin:         0 0.5rem;
    border:         none;
    pointer-events: none;
  }
  svg {
    transition:     .2s linear;
    pointer-events: none;
  }
}

.panel {
  display:         flex;
  justify-content: space-between;
  flex-wrap:       wrap;
}

.item {
  border-radius: 8px;
  overflow:      hidden;
  display:       flex;
  flex-wrap:     wrap;
  align-items:   flex-start;
  margin-bottom: 1rem;
  width:         49%;
  background:    #F1F1F1;
  box-shadow:    inset -2px 2px 4px rgba(205, 205, 205, 0.2),
                 inset 2px -2px 4px rgba(205, 205, 205, 0.2),
                 inset -2px -2px 4px rgba(255, 255, 255, 0.9),
                 inset 2px 2px 5px rgba(205, 205, 205, 0.9);
  .image {
    width:  100%;
    height: 128px;
  }
  img {
    width:      100%;
    height:     100%;
    object-fit: cover;
  }
  .description {
    padding:        8px;
    width:          100%;
    height:         calc(100% - 128px);
    display:        flex;
    flex-direction: column;
  }
  .price {
    display:     flex;
    align-items: center;
    h4 {
      line-height: 1;
    }
  }
  .itemTitle {
    display:       flex;
    align-items:   center;
    margin-bottom: 8px;
    span {
      margin-right: 4px;
    }
  }
  .info {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    margin-top:      auto;
    h4 {
      margin-bottom: 0;
    }
  }
}
@import "../../../../assets/scss/variables";

.banner {
  margin: 22px 0 0 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  padding: 4px;
  background: $backgroundColor;
  box-shadow: -5px 5px 10px rgba(217, 217, 217, 0.2),
    5px -5px 10px rgba(217, 217, 217, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(217, 217, 217, 0.9);
  position: relative;
  .image {
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 122px;
    overflow: hidden;
    position: relative;
    h3 {
      color: $whiteColor;
      position: absolute;
      z-index: 2;
      bottom: 12px;
      left: 12px;
      margin-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: radial-gradient(
        100% 687.99% at 0% 100%,
        #000000 25.03%,
        rgba(0, 0, 0, 0.32) 53.12%
      );
      opacity: 0.3;
      border-radius: 10px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}

@import "../../../../assets/scss/variables";

.search {
  padding:        0 15px;
  display:        flex;
  flex-direction: column;
  align-items:    flex-start;
}

.banner {
  margin-top: auto;
}

.card {
  margin-top:      25px;
  display:         flex;
  align-items:     center;
  justify-content: space-between;
  background:      linear-gradient(90deg, #EFEFBB 0%, #D4D3DD 100%);
  border-radius:   10px;
  padding:         8px 18px;
  p {
    font-size: 12px;
  }
  h5, p {
    color: #39434E;
  }
  h5 {
    margin-bottom: 0;
  }
  img {
    width: 25%;
  }
}

.panel {
  display:     flex;
  align-items: center;
  padding:     10px 0;
  width:       100%;
  a {
    width:              40px;
    height:             40px;
    border-radius:      10px;
    box-shadow:         -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
                        0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
                        -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
                        0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
    -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
                        0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
                        -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
                        0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
    display:            flex;
    align-items:        center;
    justify-content:    center;
    transition:         .2s linear;
    margin-right:       8px;
    &:active {
      box-shadow:         inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
                          inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
                          inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
                          inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9),;
      -webkit-box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
                          inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
                          inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
                          inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
    }
  }
  label {
    flex:          1 1 auto;
    margin-bottom: 0;
    .searchIcon {
      position:        absolute;
      right:           10px;
      top:             50%;
      transform:       translate(0, -50%);
      width:           24px;
      height:          24px;
      background-size: contain;
      cursor:          pointer;
      &:hover svg path, &:active svg path {
        stroke: $defaultColor;
      }
    }
    input {
      height:        44px;
      background:    #F1F1F1;
      box-shadow:    inset -2px 2px 4px rgba(204, 207, 207, 0.2), inset 2px -2px 4px rgba(204, 207, 207, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(204, 207, 207, 0.9);
      border-radius: 10px;
      font-size:     16px;
    }
  }
}
